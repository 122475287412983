import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from './';


type Props = {
    children?: any,
    type?: 'button' | 'submit' | 'reset',
    staticLabel?: string,
    onClick?: (e: any) => void,
    className?: string,
    label?: string,
    icon?: React.ReactNode,
    disabled?: boolean,
    secondary?: boolean,
    color?: 'plain' | 'grey' | 'plainGrey' | 'transparent' | 'red',
    small?: boolean,
    extraSmall?: boolean,
    iconAfter?: boolean,
    smallPaddingOnSides?: boolean,
    noShadow?: boolean,
    badge?: number, //number to display as a badge
    isLoading?: boolean,
};

export const Button: (props: Props) => JSX.Element =
    ({
         children,
         type= 'button',
         staticLabel,
         onClick,
         className,
         label,
         icon,
         disabled,
         secondary,
         color,
         small,
         extraSmall,
         iconAfter,
         smallPaddingOnSides,
         noShadow,
         badge,
         isLoading
     }) => {

    let colors = 'bg-gradient-to-br from-bk-pink-dark to-bk-pink-400 from-68 hover:bg-bk-p-d hover:opacity-[.95] border-none text-white ';

    if (secondary) {
        colors = 'bg-bk-black text-white hover:opacity-[.95] ';
    }

    if (color === 'plain' && !secondary) {
        colors = 'bg-bk-p hover:bg-bk-p-l hover:opacity-[.95] border-transparent text-white ';
    }

    if (color === 'plainGrey') {
        colors = 'bg-bk-grey hover:bg-bk-grey-light border-transparent ';
    } else if (color === 'grey') {
        colors = 'bg-bk-grey-light hover:bg-bk-p-l hover:opacity-[.95] border-none hover:text-white ';
    }

    if (color === 'transparent') {
        colors = 'bg-transparent hover:bg-bk-grey border border-bk-grey-light ';
    }

    if (color === 'red') {
        colors = 'bg-bk-red-200 text-white hover:bg-bk-red-300 hover:opacity-[.95] hover:text-white ';
    }

    return(
        <button
            type={type ? type : 'button'}
            {...(onClick ? { onClick: (e) => !isLoading ? onClick(e) : () => {} } : {})}
            className={'group relative flex justify-center border min-w-64 items-center ' +
                (noShadow ? '' : 'shadow-[0px_5.77364px_9.62274px_0px_#80808033] ') +
                (color === 'transparent' ? '' : 'border-transparent ') +
            'text-md font-medium rounded focus:outline-none focus:ring-transparent ' +
            'disabled:opacity-50 ' + (small ? 'py-2 px-10 ' : (extraSmall ? 'py-2 px-5 ' : (smallPaddingOnSides ? 'py-4 px-6 ' : 'py-4 px-10 '))) + colors + (className || '')}
            disabled={disabled}
        >
            {badge ?
                <div className="w-6 h-6 bg-bk-p rounded-full absolute -right-3 -top-3 text-white text-sm flex items-center justify-between">
                    <div className="flex-1">{badge}</div>
                </div>
                : null
            }
            {icon && !iconAfter ?
                <span className={(label ? 'pr-2' : '')}>
                    {icon}
                </span>
                : null
            }
            {children ?
                <>{children}</>
                :
                null
            }
            {label ?
              (isLoading ?
                  <Loader />
                  :
                  <FormattedMessage id={label}/>
              )
              :
              null
            }
            {staticLabel ?
                <>{staticLabel}</>
                :
                null
            }
            {icon && iconAfter ?
                <span className={(label || children || staticLabel ? 'pl-2' : '')}>
                    {icon}
                </span>
                : null
            }
        </button>
    );
};